<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <b-button variant="primary" size="sm" :to='{name: "products.packages.package.create"}'>
          <BIconPlus/> New Package
        </b-button>
      </template>
    </PageMenu>

    <div class="page-with-menu">

      <div v-if="!table.isLoading">
        <b-table class="bg-white" responsive hover :items="table.records" :fields="table.fields" show-empty>
          <template #empty>
            no records
          </template>
          <template #cell(name)="data">
            <b-link :to='{name: "products.packages.package.edit", params: {packageId: data.item.id}}'>{{data.item.name}}</b-link>
          </template>
          <template #cell(rank)="data">
            <BIconStarFill/> {{data.item.rank + 1}}
          </template>
          <template #cell(status)="data">
            <b-badge v-if="data.item.status === 'active'" pill variant="success">enabled</b-badge>
            <b-badge v-else pill variant="danger">disabled</b-badge>
          </template>
          <template #cell(options)="data">
            <div class="text-right">
              <b-link :to='{name: "products.packages.package.edit", params: {packageId: data.item.id}}'><BIconPencil/></b-link>
            </div>
          </template>
        </b-table>
        <PaginationRouter v-if="table.pagination.totalPages > 1" :pagination="table.pagination" :linkGen="paginationRouter"/>
      </div>
      <b-spinner v-else label="Loading..." small></b-spinner>
    </div>
  </div>
</template>

<script>
import {BIconPencil,BIconPlus,BIconStarFill} from 'bootstrap-vue';
export default {
    components: {
      PageMenu: () => import('@/common/components/PageMenu'),
      PaginationRouter: () => import('@/common/components/Pagination.Router'),
      BIconPencil,BIconPlus,BIconStarFill
    },
    data(){
      return {
        page: {
            title: "Packages",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Product Management",
                url: this.$router.resolve({name: "products"}).href
            },
            {
                title: "Packages",
            },
        ],
        table: {
          isLoading: false,
          records: [],
          pagination: {
            currentPageNo: 1
          },
          fields: [
            {
              key: 'rank',
              label: "Rank"
            },
            {
              key: 'name',
              label: "Name"
            },
            {
              key: 'trialDays',
              label: "Trial",
              formatter: value => {
                return value > 0 ? value + " days" : "-";
              }
            },
            {
              key: 'status',
              label: "Status"
            },
            {
              key: 'options',
              label: ""
            },
          ]
        }
      }
    },
    methods: {
      paginationRouter(pageNum){
        let query =  {};
        if(pageNum > 1){
            query.page = pageNum;
        }

        return this.$router.resolve({name: 'product.packages',query: query}).href;
      },

      getResults({pageNo},filters){
        this.table.isLoading = true;
        this.$api.get('products/packages',{
          params: Object.assign({
            perPage: 50,
            page: pageNo,
          },filters || {})
        }).then(({data}) => {
          this.table.isLoading = false;
          this.table.records = data.records;
          this.table.pagination = data.pagination;
          this.page.title = `Packages (${data.total})`;
        });
      },
    },
    mounted(){
      this.getResults({pageNo: 1});

      this.$emit('set-sidebar',{active: "products"});
    },

    watch: {
      "$route.query.page": function(pageNo){
        this.getResults({pageNo: pageNo});
      }
    },
};
</script>
